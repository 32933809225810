exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-us-js": () => import("./../../../src/pages/about-us/components/about-us.js" /* webpackChunkName: "component---src-pages-about-us-components-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-all-tours-components-listings-js": () => import("./../../../src/pages/all-tours/components/listings.js" /* webpackChunkName: "component---src-pages-all-tours-components-listings-js" */),
  "component---src-pages-all-tours-index-js": () => import("./../../../src/pages/all-tours/index.js" /* webpackChunkName: "component---src-pages-all-tours-index-js" */),
  "component---src-pages-contact-us-components-contact-js": () => import("./../../../src/pages/contact-us/components/contact.js" /* webpackChunkName: "component---src-pages-contact-us-components-contact-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/about.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-gallery-js": () => import("./../../../src/pages/home/components/gallery.js" /* webpackChunkName: "component---src-pages-home-components-gallery-js" */),
  "component---src-pages-home-components-hero-js": () => import("./../../../src/pages/home/components/hero.js" /* webpackChunkName: "component---src-pages-home-components-hero-js" */),
  "component---src-pages-home-components-packages-js": () => import("./../../../src/pages/home/components/packages.js" /* webpackChunkName: "component---src-pages-home-components-packages-js" */),
  "component---src-pages-home-components-reviews-js": () => import("./../../../src/pages/home/components/reviews.js" /* webpackChunkName: "component---src-pages-home-components-reviews-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/home/components/services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-home-components-tours-js": () => import("./../../../src/pages/home/components/tours.js" /* webpackChunkName: "component---src-pages-home-components-tours-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-tourdetails-js": () => import("./../../../src/templates/tourdetails.js" /* webpackChunkName: "component---src-templates-tourdetails-js" */)
}

